<template>
  <div>
    <el-card>
      <common-user-center-title :title="'收益明细'"></common-user-center-title>
      <!-- 我的收益明细 -->
      <div class="profit-title">
        <div class="details">我的总收益<span>￥{{distributionUserInfo.total_money}}</span></div>
        <router-link to="/distribution/applyProfit">
          <el-button type="danger" size="mini">去提现</el-button>
        </router-link>
      </div>
      <el-table :data="distributionProfitList" border stripe>
        <el-table-column type="index" />
        <el-table-column label="订单号" prop="no" />
        <el-table-column label="下单人" prop="name" />
        <el-table-column label="头像" width="70" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              trigger="hover"
            >
              <el-image
                slot="reference"
                style="width: 40px; height: 40px"
                :src="scope.row.image"
                fit="cover"
              />
              <el-image
                style="width: 220px; height: 220px"
                :src="scope.row.image"
                fit="cover"
              />
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="收益" width="100" prop="money" />
        <el-table-column label="下单时间" prop="created_at" />
      </el-table>
      <!-- 分页区域 -->
      <el-pagination class="mt-2"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
  </div>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import { getDistributionProfitListUrl } from '@/api/index'
export default {
  name: 'ProfitDetails',
  components: {
    commonUserCenterTitle
  },
  data(){
    return{
      distributionUserInfo:{},
      distributionProfitList:[],
      queryInfo: {
        distributeUserId: '',
        pageSize: 10,
        page: 1,
        field: '',
        desc: '',
      },
      total: 0
    }
  },
  created () {
    this.initData()
  },
  methods:{
    initData(){
      this.getDistributionUserInfo()
      // 因为要先获取storage里面的个人信息，所以异步执行
      setTimeout(()=>{
        this.getDistributionProfitList()
      })

    },
    // 获取推广员中心个人信息
    getDistributionUserInfo () {
      this.distributionUserInfo = JSON.parse(window.sessionStorage.getItem('distributionUserInfo'))
      console.log(this.distributionUserInfo)
    },
    // 获取我的收益明细列表
    async getDistributionProfitList(){
      console.log(this.distributionUserInfo)
      this.queryInfo.distributeUserId = this.distributionUserInfo.id
      const{data:res} = await this.$http.get(getDistributionProfitListUrl,{params:this.queryInfo})
      if(res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.distributionProfitList = res.data.list
      this.total = res.data.total
    },
    // 分页页码显示条数
    handleSizeChange(newSzie) {
      // 把每页显示多少数据重新赋值
      this.queryInfo.pageSize = newSzie
      // 重新获取数据并显示
      this.getDistributionProfitList()
    },
    // 页面切换事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      // 重新获取数据并显示
      this.getDistributionProfitList()
    },
  }
}
</script>

<style lang="less" scoped>
.profit-title{
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  .details{
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    span{
      color: #EF001C;
      font-weight: bold;
      margin: 0 20px 0 5px;
    }
  }
}
</style>
